.section_auth {
  padding-top: 150px;
  padding-bottom: 150px;
  z-index: 2;
  // position: relative;
  height: 100%;
}

.side_bg {
  position: absolute;
  background-color: #f1f5fd;
  width: 48%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url("/assets/images/background_signin.png");
  background-repeat: no-repeat;
  z-index: -1;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
}

// Logo and title
.logo_and_title_wrapper {
  margin-bottom: 50px;
  max-width: 280px;
  text-align: center;
  svg {
    margin-bottom: 20px;
  }
  .title {
    font-weight: 600;
    font-size: 22px;
    color: #181c32;
  }
  .image_wrapper {
    margin-right: auto;
  }
}

// right wrapper
.right_wrapper {
  max-width: 440px;
  align-self: center;
  .form_title_wrapper {
    margin-bottom: 60px;
    .form_title {
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;
      color: #181c32;
    }
    .form_sub_title {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #a7a8bb;
      a {
        color: #f47f20;
      }
    }
  }
}

// form wrapper
.form_wrapper {
  .form_group {
    margin-bottom: 30px;
    position: relative;
    label {
      position: absolute;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5;
      color: #3f4254;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
      background-color: #fff;
      left: 20px;
      top: -17px;
    }
    input {
      background-color: #fff;
      border-radius: 8px;
      padding: 20px 30px;
      border: 1px solid #eee;
      font-weight: 500;
      font-size: 14px;
      &.error {
        border: 1px solid #f47f20;
        box-sizing: border-box;
        box-shadow: 0px 4px 15px rgba(244, 127, 32, 0.15);
        border-radius: 8px;
      }
      &::placeholder {
        font-size: 14px;
        line-height: 21px;
        color: #bdbdbd;
      }
    }
    .error_msg {
      font-size: 12px;
      line-height: 20px;
      color: #e47c27;
      margin-left: 30px;
      margin-top: 6px;
    }
  }
}

.forget_password {
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: block;
  color: #3699ff;
}

.btn_group_wrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.left_wrapper {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 1300px) {
  .left_wrapper {
    margin-left: 0px;
  }
}

@media (max-width: 1000px) {
  .btn_group_wrapper {
    flex-direction: column;
    gap: 25px;
  }
  .btn_social_group {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .section_auth {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .wrapper {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .side_bg {
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
  // Logo and title
  .logo_and_title_wrapper {
    margin-bottom: 30px;
    max-width: 100%;
    .title {
      font-size: 20px;
    }
  }
  .left_wrapper {
    margin-left: 0px;
  }
  .right_wrapper {
    max-width: 100%;
  }
  .image_wrapper {
    display: none;
  }
  .form_title_wrapper {
    text-align: center;
  }
  .form_wrapper {
    max-width: 100%;
    .type_wrapper {
      justify-content: center;
    }
  }
}

@media (max-width: 600px) {
  .section_auth {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .side_bg {
    display: none;
  }
  .logo_and_title_wrapper {
    padding: 0 0px;
    .title {
      font-size: 18px;
    }
  }
  // right wrapper
  .right_wrapper {
    .form_title_wrapper {
      .form_title {
        font-size: 25px;
      }
      .form_sub_title {
        font-weight: normal;
        font-size: 16px;
      }
    }
  }
  .btn_group_wrapper {
    margin-top: 15px;
  }

  .terms_wrapper {
    margin-top: 15px;
  }
}
