/* ==============
  General
===================*/

// Reset
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.zodit-react-container {
  .zodit-wysiwyg ol,
  ul {
    padding-left: 35px;
  }
}
@font-face {
  font-display: swap;
}
::selection {
  color: #f47f20;
  background: rgba($color: #f47f20, $alpha: 0.15);
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    margin-top: auto;
  }
}

html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  background-repeat: repeat;
  background: $bg-body;
  font-family: $font-family;
  color: $body-color;
  font-size: $base-font;
  -webkit-font-smoothing: antialiased;
  @media (max-width: 600px) {
    font-size: 14px;
  }
}

input:-webkit-autofill {
  -webkit-text-fill-color: #333 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
}

// arrow off for number field

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// @media (max-width: 991px) {
//   body {
//     overflow-x: hidden;
//   }
// }

svg {
  max-width: 100%;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}
a {
  color: $body-color;
  // color: #3699ff;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}

// Typography
p {
  line-height: 34px;
  // color: #828282;
  color: #202b40;
  overflow-wrap: anywhere;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  line-height: 1.4;
  color: #3f4254;
}

h1 {
  font-size: 48px;
  font-weight: 600;
  // line-height: 70px;
}
h2 {
  font-size: 34px;
  font-weight: 600;
  // line-height: 56px;
}
h3 {
  font-size: 30px;
  font-weight: 600;
  // line-height: 45px;
}
h4 {
  font-size: 25px;
  font-weight: 500;
  // line-height: 38px;
}
h5 {
  font-size: 20px;
  font-weight: 500;
  // line-height: 30px;
}
h6 {
  font-size: 14px;
}

.font-xlarge {
  font-size: 22px;
}

.font-large {
  font-size: 18px;
}
.font-medium {
  font-size: 16px;
}

.font-small {
  font-size: 14px;
}
.font-xsmall {
  font-size: 10px;
}

@media (max-width: 900px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 23px;
  }
  h4 {
    font-size: 19px;
  }

  .font-xlarge {
    font-size: 20px;
  }

  .font-large {
    font-size: 15px;
  }
  .font-medium {
    font-size: 12px;
  }
  .font-small {
    font-size: 12px;
  }

  p {
    line-height: 1.8;
  }
}

// Container
.container,
.container-extend {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

// Container extend

@media (max-width: 1200px) {
  .container-extend {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1200px) {
  .container-extend {
    max-width: 96%;
  }
}

@media (min-width: 1660px) {
  .container-extend {
    max-width: 1600px;
  }
}

@media (min-width: 1920px) {
  .container-extend {
    max-width: 1760px;
  }
}

// Container general

.container {
  max-width: 1170px;
  padding-left: 20px;
  padding-right: 20px;
}

.container-alt {
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  width: $width;
  @media screen and (min-width: 1600px) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

// Container admin
.container-admin {
  padding-left: 40px;
  padding-right: 40px;
}

.poster > div > div {
  background: #fff !important;
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  padding: 10px !important;
  line-height: 1 !important;
  position: relative;
  z-index: 100;
}
.poster > div > div:before {
  width: 70px;
  height: 70px;
  background: #fff;
  content: "";
  position: absolute;
  left: -10px;
  top: -10px;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.5;
}
.poster > div div svg {
  font-size: 3rem;
  color: #000;
  margin-top: -9px;
}
.menu-c {
  display: none;
}

.slick-dots li.slick-active {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

// line-clamp
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// Buttons
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  padding: 7px 12px;
  background-color: transparent;
  transition: all 0.3s;
  &.admin {
    height: 30px;
    padding: 0 12px;
    font-size: 11px;
    letter-spacing: 0.6px;
    &.btn-cancel {
      height: 30px;
      padding: 0 12px;
      font-size: 11px;
      letter-spacing: 0.6px;
      background-color: #fff !important;
      border: 1px solid #eee;
      &:hover {
        box-shadow: inset 0 0 0 0.5px rgb(0 0 0 / 40%);
      }
    }
    &.btn-admin-outline {
      color: #183247;
      height: 30px;
      padding: 0 12px;
      font-size: 11px;
      letter-spacing: 0.6px;
      background-color: transparent;
      border: 1px solid #cfd7df;
      &:hover {
        box-shadow: inset 0 0 0 0.5px rgb(0 0 0 / 40%);
      }
    }
    &.btn-grey {
      background-color: #e0e0e0;
      color: #333;
      &:hover {
        background-color: #c5c5c5;
      }
    }
  }

  &.btn-round {
    border-radius: 30px;
  }

  &.btn-full {
    width: 100%;
  }

  &.btn-md {
    padding: 10px 15px;
  }
  &.btn-bg {
    padding: 15px 30px;
  }
  &.btn-xl {
    padding: 15px 50px;
  }

  &.btn-secondary-box-shadow {
    box-shadow: 0px 16px 40px rgba(244, 127, 32, 0.25);
  }
  &.btn-secondary {
    background-color: $brand;
    color: $white;
    border: 1px solid $brand;
    &:hover {
      border: 1px solid $color-primary;
      background-color: $color-primary;
    }
  }
  &.btn-primary {
    background-color: $color-primary;
    color: $white;
    border: 1px solid $color-primary;
    &:hover {
      border: 1px solid $brand;
      background-color: $brand;
    }
  }

  &.btn-outline {
    color: $brand;
    border: 1px solid $brand;
    &:hover {
      color: $white;
      border: 1px solid $brand;
      background-color: $brand;
    }
  }

  &.btn-outline-grey {
    border: 1px solid #c3cad9;
    &:hover {
      border: 1px solid #f47f20;
      color: #fff;
      background-color: #f47f20;
    }
  }

  &.btn-cancel {
    background-color: #edf5fc;
    border: 1px solid #edf5fc;
    color: #6b7a99;
    &:hover {
      background-color: #d9ecfa;
      border: 1px solid #d9ecfa;
    }
  }

  &.btn-white {
    color: $brand;
    background-color: $white;
    &:hover {
      background-color: $color-primary;
      color: #fff;
    }
  }

  &.btn-grey-border {
    color: $color-primary;
    background-color: $white;
    border: 2px solid #c3cad9;
    &:hover {
      background-color: $color-primary;
      color: #fff;
      border: 2px solid $color-primary;
    }
  }
}

.btn_primary {
  background: $dark;
  border: none;
  border-radius: 10px;
  color: $white;
  padding: 10px 18px;
}

.btn_group {
  display: flex;
  align-items: center;
  gap: 10px;
}
.bordered_datepicker {
  border: 1px solid #d7dbe0 !important;
  // margin-top: 18px !important;
}
// Icon button
.admin {
  &.icon_btn_outline {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    letter-spacing: 0.6px;
    background-color: transparent;
    border-radius: 4px;
    transition: all 0.2s;
    cursor: pointer;
    &.grey {
      border: 1px solid #cfd7df;
      color: #c3cad9;
    }
    svg {
      font-size: 18px;
    }
    &:hover {
      box-shadow: inset 0 0 0 0.5px rgb(0 0 0 / 40%);
    }
  }
}

// tooltip
.relative {
  position: relative;
  &:hover .uni-tooltip {
    visibility: visible;
    opacity: 1;
  }
}
.uni-tooltip {
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 10px;
  width: max-content;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #3f4254;
  position: absolute;
  background-color: #e5eaee;
  border-radius: 4px;
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #e5eaee;
  }
  &.top {
    top: unset;
    bottom: calc(100% + 10px);
    &:before {
      top: unset;
      bottom: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: unset;
      border-top: 15px solid #e5eaee;
    }
  }
}
.asterisk {
  margin-left: 2px;
  color: #ff4b55;
}
.border0 {
  border: none !important;
}

// Disabled
button:disabled {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

//error-msg
.error {
  color: red;
  margin-top: 5px;
}

// forms
.form-control {
  display: block;
  font-size: 14px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  appearance: none;
  transition: all 0.15s ease-in-out;
}
.input-wrapper {
  position: relative;
  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

form {
  width: 100%;
}

// Tables
.from_texteditor_wrapper {
  font-family: "Poppins", sans-serif !important;
  table {
    border-collapse: collapse !important;
    width: 100% !important;
    overflow: auto !important;
    @media (max-width: 900px) {
      display: flex !important;
      flex-direction: column !important;
      max-width: calc(100vw - 50px) !important;
    }
    th {
      background-color: $brand;
      color: $white;
      text-align: left;
      font-weight: 600;
      font-family: "Poppins", sans-serif !important;
    }
    td,
    th {
      padding: 12px;
      border: 1px solid rgba(118, 165, 191, 0.5);
      vertical-align: top;
      font-family: "Poppins", sans-serif !important;
    }

    tbody tr:nth-of-type(even) {
      background-color: #f1f5fd;
    }
    tbody tr:nth-of-type(odd) {
      background-color: $white;
    }
    td > li {
      list-style-image: url(/assets/icons/list-icon.svg);
      margin-left: 20px;
      margin-bottom: 12px;
    }
  }
}

.from_texteditor_wrapper * {
  font-family: "Poppins", sans-serif !important;
}

table {
  width: 100%;
}

section {
  padding-bottom: 80px;
}

@media (max-width: 900px) {
  section {
    padding-bottom: 30px;
  }
}

// Utilities
.arc {
  position: absolute;
  bottom: -120%;
  left: 50%;
  border: 2.5px solid $brand;
  display: inline-block;
  width: 110px;
  height: 60px;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  transform: rotate(-10deg) translateX(-50%);
}

.sticky_top {
  position: sticky;
  top: 0;
}
.bg_white {
  background-color: #fff !important;
}
.hover_orange {
  transition: color 0.3s;
  &:hover {
    color: #f47f20;
  }
}
.hover_blue {
  transition: color 0.3s;
  &:hover {
    color: #3699ff;
  }
}

.hover_text_underline {
  &:hover {
    text-decoration: underline;
  }
}

.mark {
  font-weight: 600;
  position: relative;
  white-space: nowrap;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 80%;
    border: 3px solid #f47f20;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    transform: rotate(-1deg);
    z-index: 0;
  }
}
.color-orange {
  color: #f47f20;
}

input,
select,
textarea {
  &:focus-visible {
    outline: none;
  }
}
button,
input,
select,
textarea {
  font-family: $font-family;
}
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="date"],
select,
textarea {
  width: 100%;
}
button {
  cursor: pointer;
}
b,
strong {
  font-weight: 500;
}
.text-center {
  text-align: center;
}
.mt10 {
  margin-top: 10px;
}
.fw400 {
  font-weight: normal;
}
.bold {
  font-weight: bold;
}
.fw600 {
  font-weight: 600;
}
.fw500 {
  font-weight: 500;
}
#wrapper {
  height: $height;
  overflow: hidden;
  width: $width;
}
.center {
  text-align: center;
}
.full-width {
  width: 100%;
  overflow: hidden;
}
.text-left {
  text-align: left;
}
.red {
  color: red;
  font-size: 13px;
}

.from_texteditor_wrapper {
  a {
    color: #f47f20;
  }
  img {
    height: auto !important;
  }
  font-family: "Poppins", sans-serif !important;
  ol {
    padding-left: 30px;
  }
  &.compare {
    ul {
      &.two {
        > li {
          width: 100% !important;
          @media (max-width: 675px) {
            width: 100%;
          }
          @media (max-width: 500px) {
            width: 100%;
          }
        }
      }
    }
  }
  ul {
    > li {
      list-style-image: url("/assets/icons/list-icon.svg");
      margin-left: 30px;
      margin-bottom: 12px;
      padding-left: 10px;
      p {
        margin-left: 5px;
        font-family: "Poppins", sans-serif !important;
      }
    }

    &.two {
      display: flex;
      flex-wrap: wrap;
      column-gap: 15px;
      > li {
        width: 44%;
        @media (max-width: 675px) {
          width: 40%;
        }
        @media (max-width: 500px) {
          width: 100%;
        }
      }
    }
  }
  p {
    margin-bottom: 15px;
    font-family: "Poppins", sans-serif !important;
  }
}

.modal-outer {
  padding-right: 10px;
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  overflow: auto;
  max-height: calc(100vh - 100px);
  &.overflow-visible {
    overflow: visible;
  }

  /* Scrollbar start */
  /* width */
  // &::-webkit-scrollbar {
  //   width: 10px;
  //   height: 6px;
  //   overflow: visible;
  //   cursor: pointer;
  //   background: #fff;
  //   border-radius: 10px;
  // }
  /* Handle */
  // &::-webkit-scrollbar-thumb {
  //   background: rgb(202, 202, 202);
  //   border-radius: 10px;
  // }

  &.student {
    background-color: #f7f8fa !important;
    border-radius: 20px !important;
  }
  &:focus-visible {
    outline: none;
  }
  &.xsm {
    width: 300px;
  }
  &.sm {
    width: 600px;
  }
  &.md {
    width: 900px;
  }
  &.lg {
    width: 1200px;
  }
  &.xl {
    width: 1300px;
  }
  &.full {
    width: 100%;
    padding: 30px;
  }
}

.modal_title {
  margin: 0;
  font-size: 20px;
  padding-left: 35px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.modal_head {
  position: relative;
  border-bottom: 1px solid #eeeff3;
  &.border_bottom_none {
    border-bottom: none !important;
  }
}
.cross_icon {
  border: none;
  position: absolute;
  background: transparent;
  width: 24px;
  height: 24px;
  right: 0;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  svg {
    width: 24px;
    height: 24px;
    stroke-width: 2;
  }
  &:hover {
    color: red;
  }
}
.modal_body {
  padding: 40px;
  // overflow: auto;
  // max-height: calc(100vh - 169px);

  /* Scrollbar start */
  /* width */
  // &::-webkit-scrollbar {
  //   width: 10px;
  //   height: 6px;
  //   overflow: visible;
  //   cursor: pointer;
  //   background: #fff;
  //   border-radius: 10px;
  // }
  /* Handle */
  // &::-webkit-scrollbar-thumb {
  //   background: rgb(202, 202, 202);
  //   border-radius: 10px;
  // }
}
.modal_footer {
  padding: 35px;
  padding-top: 0;
}

@media (max-width: 991px) {
  .modal.md {
    width: calc(100% - 30px) !important;
  }
}

@media (max-width: 600px) {
  .modal {
    width: calc(100% - 30px) !important;
  }

  .modal_body {
    padding: 20px;
  }
}
// Modal end

// Form
.form_group {
  margin-bottom: 20px;
}
.label {
  color: #f47f20;
  display: block;
  margin-bottom: 10px;
}

.form_control {
  border: none;
  border-bottom: 1px solid #eee;
  padding: 10px;
  padding-left: 0;
  color: $font-color;
  font-weight: 500;

  &::placeholder {
    color: #929dba;
    font-weight: 400;
  }
}
.basic_location {
  text-align: left;
  .select__control {
    border: none;
    min-height: 43px;
    -webkit-box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    text-align: left;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.9;
    .select__control {
      background: #e6e6e6;
    }
  }
}

// Advanced
.basic_location {
  .select__control {
    min-height: 50px;
    box-shadow: none;
    background-color: #f7f7fe;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }
}

.sub_title {
  // font-size: 14px;
  // line-height: 1.5;
  // color: #828282;
}

//student_profile_layout
.student_profile_layout {
  .student_profile_layout2 {
    border: 1px solid #ebedf2;
  }
  .student_profile_layout2:last-child {
    border-radius: 0 0 20px 20px;
  }
}

//common layout
.search-field {
  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  .list_search_icon {
    position: absolute;
    left: 10px;
    color: #dadada;
    font-size: 18px;
  }
  .form-control {
    border: none;
    background: #f5f8fa;
    border-radius: 6px;
    padding: 8px 14px 8px 35px;
    width: 100%;
    height: 40px;
  }
}
.form-group {
  .small-label {
    font-size: 12px;
    color: #999;
  }
}

.swal2-container {
  z-index: 20000 !important;
}

.invalid-feedback {
  font-size: 11px;
  color: #ff7e4c;
  &.frontend {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .hide-sm {
    display: none;
  }
}
.popOpen .topwrap {
  padding-right: 39px;
}

@media (max-width: 1200px) {
  .overview_page_content {
    padding-left: 30px;
  }
}

// filter_and_indector_wrapper_mobile
.filter_and_indector_wrapper_mobile {
  display: none;
}
@media (max-width: 1024px) {
  .filter_and_indector_wrapper_mobile {
    display: block;
  }
}

// Custom checkbox design
.checkbox_container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
  transition: all 0.2s;
}
.checkbox_container:hover input ~ .checkbox_checkmark {
  background-color: #ccc;
}
.checkbox_container input:checked ~ .checkbox_checkmark {
  background-color: #f47f20;
}
.checkbox_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox_container input:checked ~ .checkbox_checkmark:after {
  display: block;
}
.checkbox_container .checkbox_checkmark:after {
  top: 48%;
  left: 50%;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

// Custom Radio design
.radio_container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  transition: all 0.2s;
}
.radio_container:hover input ~ .radio_checkmark {
  background-color: #ccc;
}
.radio_container input:checked ~ .radio_checkmark {
  background-color: #f47f20;
}
.radio_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.radio_container input:checked ~ .radio_checkmark:after {
  display: block;
}
.radio_container .radio_checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: white;
}

// Lead form
.lead_form_insidepage_wrapper {
  margin-top: 50px;
  background: #f1f5fd;
  box-shadow: 0 5px 10px rgb(150 185 255 / 25%);
  border-radius: 10px;
  padding: 50px;
  .lead_form_insidepage_heading_wrapper {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .lead_form_insidepage_wrapper {
    margin-top: 30px;
    padding: 20px;
  }
}
.vlog_socile_wrapper {
  flex-direction: row !important;
  padding-top: 6px;
}
