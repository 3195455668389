// React Pro Sidebar start
@media (min-width: 768.98px) {
  .AdminSidebar {
    .pro-sidebar {
      height: 100vh;
      position: sticky;
      top: 0;
      left: 0;
    }
    .pro-sidebar
      > .pro-sidebar-inner
      > .pro-sidebar-layout
      .pro-sidebar-content {
      &:hover {
        &::-webkit-scrollbar-thumb {
          background: rgba(214, 214, 214, 0.3);
        }
      }
      &::-webkit-scrollbar {
        width: 4px;
        overflow: visible;
        cursor: pointer;
        background: transparent;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        width: 5px;
        background: transparent;
        border-radius: 10px;
        transition: all 0.3s;
        cursor: pointer;
      }
    }
    .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
      padding-right: 2px;
    }
  }
}

.MuiPaginationItem-root {
  margin: 0 !important;
}

.ShowTooltip {
  display: none;
}

.AdminSidebar {
  .pro-sidebar {
    color: #fff;
    width: 248px;
    min-width: 248px;
  }
  .pro-sidebar.collapsed {
    width: 50px;
    min-width: 50px;
  }
  .pro-sidebar.collapsed {
    width: 50px;
    min-width: 50px;
  }
  .pro-sidebar > .pro-sidebar-inner {
    background-color: #0c1333;
  }
  .pro-sidebar .pro-menu a {
    color: #fff;
    transition: all 0.3s;
    &.active {
      color: #f47f20;
    }
  }
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    overflow-y: unset;
    overflow-x: unset;
  }
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 1px 4px;
  }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: #0c1333;
    transition: all 0.3s;
  }
  .pro-sidebar
    .pro-menu
    > ul
    > .pro-sub-menu
    > .pro-inner-list-item
    > div
    > ul {
    padding-top: 0;
    padding-bottom: 0;
  }
  .pro-sidebar .pro-menu {
    padding-top: 0;
    padding-bottom: 0;
  }

  .pro-sidebar .pro-menu a,
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item,
  .pro-sidebar .pro-menu .pro-menu-item {
    transition: color 0.3s;
  }
  .pro-sidebar .pro-menu a:hover,
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover,
  .pro-sidebar .pro-menu .pro-menu-item.active {
    color: #f47f20;
    svg {
      path {
        transition: fill 0.3s;
        fill: #f47f20;
      }
    }
  }
  .pro-sidebar .active .pro-icon {
    svg {
      path {
        fill: #f47f20;
      }
    }
  }
  .pro-sidebar {
    .pro-menu
      .pro-menu-item.pro-sub-menu
      > .pro-inner-item
      > .pro-arrow-wrapper
      .pro-arrow {
      border-width: 0 1px 1px 0;
      padding: 3px;
    }
  }
  .pro-sidebar
    .pro-menu
    .pro-menu-item
    > .pro-inner-item
    > .pro-icon-wrapper
    .pro-icon {
    font-size: 20px;
    animation: none !important;
  }
  .pro-sidebar .pro-menu a:before {
    display: none;
  }
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: 0;
  }
  .pro-sidebar.collapsed
    .pro-menu
    > ul
    > .pro-menu-item.pro-sub-menu
    > .pro-inner-list-item
    > .popper-inner {
    background-color: #fff;
    padding-left: 0;
    padding: 8px;
    box-shadow: 0 2px 18px 0 rgb(18 52 77 / 16%), 0 2px 4px 0 rgb(18 52 77 / 6%);
    .pro-menu-item {
      color: #12344d;
      border-radius: 4px;
      &:hover {
        background-color: #ebeff3;
      }
    }
  }

  .pro-sidebar.collapsed
    .pro-menu
    > ul
    > .pro-menu-item.pro-sub-menu
    > .pro-inner-list-item {
    min-width: 160px;
    padding-left: 0;
  }
  .pro-sidebar.collapsed
    .pro-menu
    > ul
    > .pro-menu-item.pro-sub-menu
    .pro-inner-list-item
    .pro-inner-item {
    padding: 8px;
  }

  .pro-sidebar .pro-menu .pro-menu-item {
    font-size: 13px;
  }
}

// Mobile side menu
.MobileSideMenu.pro-sidebar {
  width: 100%;
  color: initial;
  .pro-sidebar-inner {
    background: #fff;
  }
  .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background: #fff;
  }
  .pro-menu
    .pro-menu-item.pro-sub-menu
    > .pro-inner-item
    > .pro-arrow-wrapper
    .pro-arrow {
    border-width: 0 1px 1px 0;
    padding: 3px;
  }
  .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: #f47f20;
  }
  .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
    padding-top: 0;
    padding-bottom: 0;
  }
  .pro-menu a {
    text-decoration: none;
    color: initial;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 220px;
  }
}

// React Pro Sidebar end

// Tab from material ui
.MuiTypography-root {
  font-family: $font-family !important;
}
.MuiBox-root {
  padding: 40px 0px !important;
  @media (max-width: 600px) {
    padding: 20px 0px !important;
  }
  .MuiTypography-root {
    font-family: $font-family;
    font-size: unset;
    letter-spacing: unset;
  }
}

.uni_courses,
.uni_campuses {
  .MuiAppBar-colorDefault {
    background-color: $white;
  }
}

.uni_courses {
  .MuiAppBar-colorDefault {
    background-color: transparent;
  }
  .MuiTabs-flexContainer {
    @media (min-width: 768px) {
      justify-content: center;
    }
  }
}

.overview_courses {
  .MuiAppBar-colorDefault {
    background-color: $bannerBg;
  }
}

.MuiPaper-root {
  &.MuiAppBar-colorDefault {
    box-shadow: none;
  }
  .MuiTab-root {
    padding: 0;
    margin-right: 30px;
    font-size: 16px;
    max-width: unset;
    min-width: unset;
    font-family: $font-family;
    font-weight: 600;
    color: $body-color;
    text-transform: initial;
    letter-spacing: unset;
  }
  .MuiTab-textColorPrimary.Mui-selected {
    color: $brand;
  }
  .MuiTouchRipple-root {
    display: none;
  }
  .MuiTabs-indicator {
    background-color: $brand;
  }
}

// ExamFreeDemoTabs
.ExamFreeDemoTabs {
  .MuiTabs-root {
    min-height: unset;
  }
  .MuiAppBar-colorDefault {
    background-color: $white;
  }
  .MuiTab-textColorPrimary.Mui-selected {
    color: #fff;
    background-color: $brand;
    &:hover {
      color: #fff;
    }
  }
  .MuiTab-root {
    padding: 8px 16px;
    margin-right: 8px;
    border-radius: 6px;
    min-height: unset;
    transition: all 0.3s;
    &:hover {
      color: #f47f20;
    }
  }
  .MuiPaper-root .MuiTabs-indicator {
    display: none;
  }
}

@media (min-width: 600px) {
  .ExamFreeDemoTabs {
    .MuiAppBar-root {
      align-items: flex-start;
    }
  }
  .ElearningCourseTab {
    .MuiTabs-flexContainer {
      justify-content: center;
    }
  }

  .MuiTabs-scrollButtons {
    display: none;
  }
}

// Accordion from material ui
.MuiCollapse-root {
  .MuiAccordionDetails-root {
    display: block;
  }
}

// Range slider from material ui
.advanced_search_range_wrapper {
  .MuiSlider-thumb {
    width: 20px;
    height: 20px;
  }
  .css-eg0mwd-MuiSlider-thumb {
    top: 50%;
  }
}
.MuiSlider-root {
  color: $brand !important;

  .MuiSlider-track {
    background-color: $brand;
    height: 3px;
  }
  .MuiSlider-rail {
    background-color: $font-color;
    height: 3px;
    opacity: 1;
  }
  .MuiSlider-thumb {
    background-color: $white;
    border: 2px solid $brand;
  }
  .MuiSlider-valueLabel {
    left: calc(-50% - 8px);
  }

  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 8px rgba(244, 127, 31, 0.251);
  }
}

// Slick slider from react-slick
ul.slick-dots li {
  margin: 0px 0px;
}
ul.slick-dots li button:before {
  font-size: 13px;
}
ul.slick-dots li.slick-active button:before {
  color: $brand;
}

ul.slick-dots li button:hover:before,
ul.slick-dots li button:focus:before {
  color: $brand;
}

// Checkbox
.MuiCheckbox-root {
  padding: 4px 8px;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #0f1041;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: #d8d6de;
}

// Drawer
.MuiPaper-root {
  // width: 100%;
}

.blog_top_story_tab {
  .MuiButtonBase-root {
    background-color: #fff;
    outline: 0;
    border: 1px solid #ced0d4;
    border-radius: 6px;
    margin-right: 20px;
    padding: 0;
    font-family: "Poppins", sans-serif;
    text-transform: initial;
    max-width: unset;
    min-width: 90px;
    min-height: unset;
    padding: 12px 16px;
    overflow: unset;
    white-space: normal;
    text-align: center;
    font-size: 18px;
    &.Mui-selected {
      color: $brand;
      // border: 1px solid transparent;
      // box-shadow: 0px 4px 10px rgba(37, 47, 63, 0.1);
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-scrollButtons {
    display: none;
  }
}

.PrivateSwipeArea-root {
  width: 10px !important;
}

// React video
@media (max-width: 600px) {
  .react-video {
    height: auto !important;
  }
}

// .intro_video2 {
//   .poster {
//     display: none;
//   }
// }

.intro_video1,
.intro_video3 {
  .poster > div div {
    display: none;
  }
}

// uni overview gallery
.uni_overview_gallery_wrapper {
  .uni_overview_gallery {
    slick-slide {
      height: auto;
      overflow: hidden;
      box-sizing: border-box;
      transform: translate(2%, 0) scale(0.9);
    }
  }
}

// Search page
.rct-checkbox *,
.rct-collapse *,
.rct-node-icon * {
  width: 22px;
  height: 22px;
  min-width: 22px;
}
.rct-node-icon {
  padding: 0;
}

.react-checkbox-tree label {
  display: flex;
}

.react-checkbox-tree ol ol {
  padding-left: 18px;
}
.filter_course_level,
.filter_exam,
.filter_application,
.filter_scholarship {
  .rct-collapse {
    display: none;
  }
}

// Uni overview gallery
.uni_overview_gallery_slider {
  .slick-prev {
    left: 2%;
    z-index: 1;
  }
  .slick-next {
    right: 4%;
    z-index: 1;
  }
  .slick-arrow:before {
    font-size: 54px;
    background: transparent;
    color: #fff;
  }
  .slick-track {
    display: flex;
    max-height: 750px;
  }

  .slick-slide {
    display: block;
    height: auto;
    overflow: hidden;
    box-sizing: border-box;
    transform: scale(0.93);
    border-radius: 12px;
    div {
      width: 100%;
      height: 100%;
    }
    &.slick-current {
      transform: scale(1);
    }
    img {
      border-radius: 12px;
      max-height: 100%;
      object-fit: cover;
      min-height: 100%;
    }
  }
}

.uni_overview_gallery_thumbnails {
  max-width: calc(100vw - 250px);
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;

  .slick-track {
    display: flex;
    justify-content: center;
    .slick-slide.slick-current img {
      padding: 3px;
      border: 3px solid #8e8f91;
      width: calc(100% - 6px);
    }
    .slick-slide {
      padding-right: 25px;
      max-width: 260px;
    }
    img {
      border-radius: 12px;
    }
  }
}

// Testimonials
.testimonial_wrapper {
  .slick-list {
    padding: 80px 60px !important;
  }
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: auto;
  }

  .slick-dots {
    bottom: 15px;
  }

  .slick-current {
    transform: scale(1.2);

    .test_quote_icon,
    .test_video_icon {
      color: $brand;
    }

    .test_text {
      color: #0f1041;
    }
  }
}

// Partner slider
.partner_slider .slick-dots {
  bottom: -60px;
}

// Menu dropdown
.uni_menu {
  .css-6hp17o-MuiList-root-MuiMenu-list {
    background: #ffffff;
    box-shadow: 1px 1px 3px 1px rgba(176, 218, 255, 0.25);
    border-radius: 4px;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    text-decoration: none;
    background-color: #edf5fc;
    color: #f47f20;
  }
}

//mui select

.uni_select_bg {
  .css-1gebcc0-control {
    background-color: #f5f8fa;
    border-radius: 5px;
  }
}
.select_border_none {
  .css-1gebcc0-control {
    border: none;
  }
}

// .uni_select {
//   border-radius: 5px;
//   .MuiOutlinedInput-notchedOutline {
//     border: none;
//   }
// }

// .uni_select_gray {
//   background: #f5f8fa;
//   box-shadow: 0px 2px 5px rgb(38 51 77 / 3%);
// }

// .uni_select_opacity {
//   .css-yf8vq0-MuiSelect-nativeInput {
//     opacity: 1;
//     border: none;
//     position: absolute;
//     top: 0;
//     padding-left: 15px;
//     border-radius: 5px;
//   }
// }

// .uni_select_bg {
//   .css-yf8vq0-MuiSelect-nativeInput {
//     background: #f5f8fa;
//   }
// }

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  // box-shadow: 1px 1px 3px 1px rgba(176, 218, 255, 0.25) !important;
  border-radius: 4px !important;
}

.MuiMenu-paper {
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background: #edf5fc !important;
  color: #f47f20 !important;
  font-weight: 500 !important;
  font-size: 14px;
}

.select_option {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #b5b5c3 !important;
  transition: all 0.2s;
}

.select_option:hover {
  background: #edf5fc !important;
  color: #f47f20 !important;
  font-weight: 500 !important;
  font-size: 14px;
}

// sun text editor
.uni_richtextarea {
  .sun-editor {
    border: none;
    .se-resizing-bar {
      display: none;
    }
    .se-toolbar {
      background-color: #fff;
      outline: none;
      border-top: 1px solid #eeeff3;
      border-bottom: 1px solid #eeeff3;
      padding: 10px 25px;
    }
  }
  .se-menu-list li {
    box-shadow: unset;
  }
}

// mui pagination
.custom_pagination {
  .Mui-selected {
    background-color: #f47f20 !important;
    color: #fff;
  }
}

// Community slier
.community_slider {
  .slick-dots {
    text-align: left;
    margin-left: 75px;
    @media (max-width: 900px) {
      margin-left: 0px;
      text-align: center;
    }
  }
}

// Tab slider two
.tab_slider_two {
  .poster > div {
    border-radius: 10px !important;
    background: transparent;
  }
  .poster > div img {
    filter: drop-shadow(0px 6px 14px rgba(150, 185, 255, 0.25));
    border-radius: 10px !important;
    object-fit: cover;
  }
}

// top university slider
.top-box-container,
.instructioin-video-slider {
  .slick-prev:before,
  .slick-next:before {
    color: orange;
  }

  .slick-dots {
    bottom: -40px;
  }
}

// Instruction video gallery
.instructioin-video-slider {
  .slick-slide {
    padding: 20px;
    @media (max-width: 600px) {
      padding: 0;
    }
  }
}

// Instruction video gallery
.instructioin-video-slider.exam-free-demo-slider {
  .slick-slide {
    padding: 15px;
    @media (max-width: 600px) {
      padding: 0;
    }
  }
}

// Visa Option slider
.visa-option-slider {
  .slick-slide {
    padding: 20px;
    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .slick-arrow.InstructionGallerySliderNext,
  .slick-arrow.InstructionGallerySliderPrev {
    top: -10px;
    @media (max-width: 600px) {
      top: -25px;
    }
  }

  .slick-arrow.InstructionGallerySliderNext {
    @media (max-width: 600px) {
      right: 10px;
    }
  }
  .slick-arrow.InstructionGallerySliderPrev {
    @media (max-width: 600px) {
      right: 50px;
    }
  }
}

// Top Ranking university card
.TopRankCardSlider {
  .slick-slide {
    padding: 15px;
    & > div {
      padding-bottom: 30px;
    }
  }

  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
  }
}
.popular_course_dropdown {
  .MuiList-padding {
    padding: 0 !important;
  }
}

@media (max-width: 600px) {
  .TopRankCardSlider .slick-slide {
    padding: 0;
  }
}

// Profile Dropdown{
.ProfileDropdown {
  .MuiList-padding {
    padding: 0 !important;
    min-width: 280px;
  }
}
.HeaderNotification {
  .MuiList-padding {
    padding: 0 !important;
    width: 350px !important;
  }
}

// Review Slider
.ReviewSliderPerson {
  .slick-slide {
    padding: 80px 50px 0px 50px;
  }
  .slick-center {
    .review_person_image_wrapper {
      margin-top: -30px;
      transform: scale(1.6);
      border: 5px solid #fbc91b;
      border-radius: 50%;
      transition: all 0.3s;
    }
  }
}
.slick-arrow {
  &.ReviewSliderTextArrowNext,
  &.ReviewSliderTextArrowPrev {
    padding: 8px;
    box-sizing: content-box;
    font-size: 25px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    color: #fff;
    background-color: #f47f20;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s;
    &:hover {
      background-color: #0f1041;
      color: #fff;
    }
  }
  &.ReviewSliderTextArrowNext {
    right: 35px;
  }
  &.ReviewSliderTextArrowPrev {
    left: 35px;
  }
  &.slick-disabled {
    background-color: #dcdcdc;
    &:hover {
      background-color: #dcdcdc;
      cursor: default;
    }
  }
}

.slick-arrow {
  &.vfair_right_arrow,
  &.vfair_left_arrow {
    padding: 8px;
    box-sizing: content-box;
    font-size: 25px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    color: #7e9596;
    background-color: #ffffff;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s;
    &:hover {
      background-color: #0f1041;
      color: #7e9596;
    }
  }
  &.vfair_right_arrow {
    right: -140px !important;
  }
  &.vfair_left_arrow {
    left: -140px !important;
  }
  &.slick-disabled {
    background-color: #dcdcdc;
    &:hover {
      background-color: #dcdcdc;
      cursor: default;
    }
  }
}
@media (max-width: 900px) {
  .ReviewSliderPerson {
    .slick-track {
      padding-bottom: 20px;
    }
  }
}
@media (max-width: 600px) {
  .slick-arrow {
    &.ReviewSliderTextArrowNext,
    &.ReviewSliderTextArrowPrev {
      padding: 4px;
      font-size: 20px;
    }
    &.ReviewSliderTextArrowNext {
      right: 10px;
    }
    &.ReviewSliderTextArrowPrev {
      left: 10px;
    }
  }
  .ReviewSliderPerson {
    .slick-center {
      .review_person_image_wrapper {
        border: 3px solid #fbc91b;
      }
    }
    .slick-slide {
      padding: 70px 30px 0px 30px;
    }
  }
}
@media (max-width: 500px) {
  .ReviewSliderPerson .slick-slide {
    padding: 50px 15px 0px 15px;
  }

  .ReviewSliderPerson {
    .slick-track {
      padding-top: 10px;
    }
  }
}
@media (max-width: 400px) {
  .ReviewSliderPerson .slick-slide {
    padding: 50px 12px 0px 12px;
  }
}
@media (min-width: 1921px) {
  .ReviewSliderPerson .slick-slide {
    padding: 80px 50px 20px 50px;
  }
}

// Top ranking slider
.top-rank-slider-wrapper {
  .ReviewSliderTextArrowNext {
    right: -16px;
    padding: 5px;
  }
  .ReviewSliderTextArrowPrev {
    left: -16px;
    padding: 5px;
  }
}

// Instruction Gallery Slider
.slick-arrow {
  &.InstructionGallerySliderNext,
  &.InstructionGallerySliderPrev {
    padding: 5px;
    box-sizing: content-box;
    font-size: 25px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    color: #f47f20;
    top: -32px;
    border: 1px solid #eee;
    transform: translateY(-50%);
    transition: all 0.3s;
    &:hover {
      color: #f47f20;
      border: 1px solid #eeeeee;
      box-shadow: 0px 2px 8px rgba(150, 185, 255, 0.25);
    }
  }
  &.InstructionGallerySliderNext {
    right: 20px;
    @media (max-width: 600px) {
      right: 0;
    }
  }
  &.InstructionGallerySliderPrev {
    right: 65px;
    left: unset;
    @media (max-width: 600px) {
      right: 45px;
    }
  }

  &.slick-disabled {
    background-color: #fff;
    color: #eee;
    &:hover {
      background-color: #fff;
      color: #eee;
      cursor: default;
      box-shadow: unset;
    }
  }
}

.slick-arrow {
  &.ExamFreeDemo.InstructionGallerySliderNext,
  &.ExamFreeDemo.InstructionGallerySliderPrev {
    padding: 5px;
    border-radius: 6px;
    box-sizing: content-box;
    font-size: 25px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    background-color: #f47f20;
    color: #fff;
    top: -32px;
    transform: translateY(-50%);
    transition: all 0.3s;
    &:hover {
      background-color: #0f1041;
    }

    &.slick-disabled.ExamFreeDemo.InstructionGallerySliderNext,
    &.slick-disabled.ExamFreeDemo.InstructionGallerySliderPrev {
      background-color: #c3cad9;
      color: #fff;
      &:hover {
        background-color: #c3cad9;
        color: #fff;
        cursor: default;
        box-shadow: unset;
      }
    }
  }
}

// News Homepage Slider
.slick-arrow {
  &.NewsSliderNext.slick-next {
    right: 16px;
  }
  &.NewsSliderPrev.slick-prev {
    right: 48px;
    left: unset;
  }
  &.NewsSliderNext,
  &.NewsSliderPrev {
    padding: 1px;
    box-sizing: content-box;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    background-color: #f47f20;
    color: #fff;
    top: -40px;
    transform: translateY(-50%);
    transition: all 0.3s;
    &:hover {
      background-color: #0f1041;
      color: #fff;
    }

    &.slick-disabled.NewsSliderNext,
    &.slick-disabled.NewsSliderPrev {
      background-color: #c3cad9;
      color: #fff;
      &:hover {
        background-color: #c3cad9;
        color: #fff;
        cursor: default;
        box-shadow: unset;
      }
    }
  }
}

// React select
.css-1okebmr-indicatorSeparator {
  display: none;
}

//mui select customize in student profile

.mui_select_wrapper {
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
  }
}

// Uni campus tab
.CampusMapWrapper {
  .MuiBox-root,
  .MuiTypography-root {
    height: 100%;
  }
  .MuiBox-root {
    padding: 0px !important;
  }
}

// Checkbox tree
.react-checkbox-tree {
  .rct-text {
    position: relative;
  }
  .rct-collapse.rct-collapse-btn {
    position: absolute;
    right: 5px;
  }
  .rct-title {
    font-size: 12px;
    padding-right: 20px;
    display: flex;
    align-items: center;
  }

  .rct-collapse,
  .rct-checkbox,
  .rct-node-icon {
    padding: 0;
  }
  .rct-icon {
    display: none;
  }
  .rct-checkbox *,
  .rct-collapse *,
  .rct-node-icon * {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
  label {
    transition: all 0.3s;
  }
  label:hover {
    background: none !important;
    color: initial;
  }
  span.rct-collapse {
    display: none;
  }
}

// mui table_head
td.MuiTableCell-root {
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  border-bottom: inherit;
}
th.MuiTableCell-head {
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  border-bottom: inherit;
}

.MuiTableCell-root {
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  border-bottom: inherit;
}

.compare_wrapper {
  .MuiTableCell-root {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    vertical-align: top;
    padding: 16px;
    color: inherit;
  }
}

.studentListTable {
  .css-1ex1afd-MuiTableCell-root {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
    border-bottom: inherit;
  }
}
.studentListTable {
  .css-1ygcj2i-MuiTableCell-root {
    font-family: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }
}

.SearchFilterDrawer {
  &.MuiPaper-root {
    width: calc(100% - 20px);
  }
}
div[id^="react-select-*-listbox"] {
  z-index: 100;
  &::-webkit-scrollbar {
    width: 5px;
    height: 6px;
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(202, 202, 202);
    border-radius: 10px;
    cursor: pointer;
  }
}
div[id^="react-select-*-placeholder"] {
  z-index: 0;
}

.react-datepicker__current-month {
  padding-bottom: 10px;
}
.react-datepicker__month-read-view {
  border: 1px solid #ddd;
  padding: 5px;
  background: #e9e9e9;
}
.react-datepicker__month-read-view--down-arrow {
  margin-right: 25px;
}

.react-datepicker__month-read-view--selected-month {
  margin-right: 30px;
}
.react-datepicker__month-read-view--down-arrow {
  margin-top: 5px;
}

.react-datepicker__year-dropdown-container--scroll {
  border: 1px solid #ddd;
  padding: 5px 20px 5px 5px;
  background: #e9e9e9;
}

// React datepicker
.react-datepicker__input-container input {
  cursor: pointer;
}
div.react-datepicker {
  font-family: inherit !important;
  border: 1px solid #eee;
  .react-datepicker__header {
    background-color: #fff;
    border-bottom: 0;
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
  }
  .react-datepicker__navigation-icon::before {
    top: 10px;
  }
  .react-datepicker__current-month {
    font-weight: 600;
  }
  .react-datepicker__year-dropdown {
    background-color: #fff;
  }
  .react-datepicker__year-dropdown-container--scroll {
    border: 1px solid #eee;
    border-radius: 5px;
    background-color: #f0f0f0;
  }
  .react-datepicker__month-read-view {
    background-color: #f0f0f0;
  }
  .react-datepicker__day--selected {
    background-color: #f47f20;
  }
  .react-datepicker__month-dropdown {
    background-color: #fff;
  }
  .react-datepicker__navigation--years-previous {
    background-image: url("/assets/arrow-down.svg");
    background-repeat: no-repeat;
    opacity: 0.2;
    top: 0px;
    background-position: center;
  }
  .react-datepicker__navigation--years-upcoming {
    background-image: url("/assets/arrow-up.svg");
    background-repeat: no-repeat;
    opacity: 0.2;
    top: 0px;
    background-position: center;
  }
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  border-top-color: #eee !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #fff !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #eee !important;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #f0f0f0 !important;
}

.swal2-icon.no-border {
  border: none;
}
.swal2-html-container {
  margin: 0 !important;
  font-size: 14px !important;
  color: #a1a1a1 !important;
}
.swalMessage {
  display: block;
  text-align: center;
  p {
    margin-bottom: 15px;
    display: block;
    color: #b5b5c3;
    font-weight: 400;
    font-size: 14px;
  }
}

.swal2-confirm {
  box-shadow: none !important;
  padding: 8px 25px;
  &:hover {
    background: #0f1041 !important;
  }
}

.react-tel-input .form-control {
  width: 100%;
  height: auto;
  padding: 12px 20px 12px 50px;
  border: 1px solid #eee;
  border-radius: 8px;
}
.react-tel-input .flag-dropdown {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 8px 0 0 8px;
}

// MuiDialogContent-root

.MuiDialogContent-root {
  @media (min-width: 900px) {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      overflow: visible;
      cursor: pointer;
      background: #fff;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(202, 202, 202);
      border-radius: 10px;
    }
  }
}

// Mui Drawer
@media (max-width: 900px) {
  .MuiDrawer-paper {
    width: 100% !important;
  }
}

// evaluation_form_label
@media (max-width: 600px) {
  .evaluation_form_label span {
    font-size: 14px !important;
  }
}

.MuiPickersDay-root.Mui-selected {
  background-color: #f47f20 !important;
}

// ForumSideDrawer
.ForumSideDrawer {
  height: 100%;
  background-color: #f8f8fb;
  padding-top: 50px;
  padding-bottom: 30px;
}

// BreakingNewsSlider
.BreakingNewsSlider {
  .slick-track {
    display: flex;
    .slick-slide {
      height: auto;
      > div {
        height: 100%;
      }
    }
  }
}

// React date picker
.react-datepicker-popper {
  z-index: 10;
}

// react tag component
.admin_topic_input_wrapper {
  .react-tagsinput-input {
    color: black;
  }
  .react-tagsinput {
    border-radius: 0px !important;
    border: 1px solid #ebeff3;
    padding: 0;
    .react-tagsinput-input {
      padding: 5.5px 10px;
      height: 30px;
      margin: 0;
      &::placeholder {
        line-height: 15px;
        color: #b5b5c3;
      }
    }
  }
  .react-tagsinput--focused {
    border: 1px solid #ebeff3;
  }
  .react-tagsinput-tag {
    background: #f2f2f2;
    border: none;
    font-weight: 400;
    font-size: 14px !important;
    color: #202b40;
    .react-tagsinput-remove {
      &:hover {
        color: red;
      }
    }
  }
}

.leaflet-container {
  height: 400px;
  width: 80%;
  margin: 0 auto;
  position: relative;
  z-index: 100;
  background: #f00;
  overflow: hidden;
}
.leaflet-pane {
  height: 400px;
  width: 80%;
  margin: 0 auto;
}
.text-right,
.admin_table_wrapper table th.text-right,
.admin_table_wrapper table td.text-right {
  text-align: right;
}

// email auto complete
.email_autocomplete > div {
  width: 100%;
  input {
    border: none;
    font-size: 11px;
    height: 30px;
    &::placeholder {
      line-height: 15px;
      color: #b5b5c3;
    }
  }
  input + div {
    z-index: 11;
  }
}

.email_autocomplete_error > div {
  width: 100%;
  background: #ffebeb;
  input {
    border: none;
    background: #ffebeb;
    &::placeholder {
      color: #333 !important;
      font-size: 11px;
    }
    padding: 0 10px;
  }
}

// unieditor
.reply_box_texteditor {
  .unidit-container:not(.unidit_inline) {
    border-radius: 0px;
    border: none;
    font-size: 12px;
    .unidit-wysiwyg {
      font-size: 12px;
    }
    .unidit-toolbar__box:not(:empty) {
      border-bottom: 0;
      border-radius: 0;
    }
  }
  .unidit-workplace + .unidit-status-bar:not(:empty) {
    display: none;
  }
}

// modal video
.modal-video-body {
  max-width: 1200px;
  .modal-video-inner {
    width: 100% !important;
  }
}
@media (orientation: landscape) {
  .modal-video-inner {
    padding: 10px 0px;
  }
}

// send_mail_to_counsellor_editor
.send_mail_to_counsellor_editor {
  .zodit-add-new-line {
    display: none !important;
  }
  .zodit-placeholder {
    font-size: 13px !important;
  }
  .zodit-workplace {
    font-size: 14px;
  }
}
.zodit-status-bar {
  height: auto !important;
}
.zodit-container:not(.zodit_inline) {
  border: 0 !important;
}

// review slider
.ReviewSliderText {
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: auto;
    & > div {
      height: 100%;
    }
  }
}
.zodit-add-new-line {
  display: none !important;
}
.zodit-add-new-line_after {
  display: none !important;
}
.across_slider_container {
  .image_wrapper {
    height: 90px;
    background: #ffffff;
    position: relative;
    border-left: 0.5px solid #e2e2e2;
    border-top: 0.5px solid #e2e2e2;
    scale: 1;
    transition: all 0.5s;
    &:hover {
      background: #ffffff;
      box-shadow: 0px 4px 6px #f3f3ff;
      z-index: 1000;
      scale: 1.04;
      border: transparent;
      transition: all 0.5s;
    }
    img {
      object-fit: cover;
    }
  }

  .slick-slide > div {
    // background: #ffffff;
    margin-top: -7px;
  }
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}
.course_collapse_body_wrapper {
  .MuiBox-root {
    padding: 0px !important;
    font-size: 12px;
  }
  .MuiButtonBase-root {
    margin-right: 10px;
  }
  .MuiSlider-root .MuiSlider-rail {
    background-color: #c3cad9;
    height: 4px;
  }
  .css-eg0mwd-MuiSlider-thumb {
    width: 11px;
    height: 11px;
  }
  .MuiSlider-root .MuiSlider-thumb {
    background-color: #f47f20;
    border: 2px solid #f47f20;
  }
}
