.accommodation_page {
  .heading {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  div[class^="listings-brand-container"] {
    display: none;
  }
}
