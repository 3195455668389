// admin layout styles start
.admin_layout_wrapper {
  display: flex;
  position: relative;
  height: 100vh;
  overflow: auto;
}
.admin_layout_right_wrapper {
  flex-grow: 1;
  // display: grid;
  // align-self: flex-start;
  // grid-template-rows: max-content auto max-content;
  // min-height: 100vh;
  // background: #ebeff3;
  background: #f5f5fb;
  overflow-x: hidden;
}

// Header
.admin_layout_header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
}
.admin_layout_nav {
  height: 50px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}
.nav_left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.page_info_wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.nav_right {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}
// Header end

.admin_layout_main_wrapper {
  // padding: 15px;
  background: #f5f5fb;
  // background: #ebeff3;
  // overflow-x: auto;
  // overflow-y: hidden;
}

.logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 15px;
  height: 56px;
  .logo {
    margin-right: auto;
    flex-shrink: 0;
  }
}

.collapse_icon_wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  right: -12px;
  top: 40px;
  border: 1px solid #eee;
}
.collapse_icon {
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s;
  color: #a1a5b7;
  &:hover {
    color: #f47f20;
  }
}

.menu_icon {
  display: none;
}

@media (max-width: 768px) {
  .admin_layout_main_wrapper {
    padding: 25px;
  }
  .admin_layout_nav {
    padding-left: 25px;
    padding-right: 25px;
  }
  .admin_layout_breadcrumbs_wrapper {
    height: 50px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .menu_icon {
    display: block;
    font-size: 24px;
    transition: all 0.3s;
    height: 28px;
    cursor: pointer;
    &:hover {
      color: #f47f20;
    }
  }
  .logo_wrapper2 {
    display: block !important;
  }
}
// admin layout styles end

// Action header
.action_header {
  position: sticky;
  top: 50px;
  padding: 13px;
  height: 56px;
  background: #f5f7f9;
  box-shadow: 0px 2px 4px rgba(187, 187, 187, 0.07);
  display: flex;
  justify-content: space-between;
  z-index: 10;
}
.action_header_left {
  .action_header_left_list {
    list-style: none;
    display: flex;
    align-items: center;
    li {
      padding-left: 8px;
      padding-right: 8px;
      position: relative;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      cursor: pointer;
      color: #6f7c87;
      &:not(:last-child) {
        &:before {
          height: 16px;
          width: 1px;
          background-color: #cfd7df;
          position: absolute;
          right: 0;
          content: " ";
          top: calc(50% - 8px);
        }
      }
      &:hover,
      &.active {
        color: #f47f20;
      }
    }
  }
  .action_delete {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #183247;
    background: #f9fafb;
    border: 1px solid #c3cad9;
    padding: 3px 10px;
    border-radius: 4px;
    transition: all 0.3s;
    &.disabled {
      cursor: not-allowed !important;
      opacity: 0.6;
      color: inherit !important;
    }
    &:hover {
      box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.4);
    }
    .action_delete_icon {
      color: #c3cad9;
      font-size: 16px;
    }
  }
  .action_search {
    width: 225px;
    position: relative;
    .action_search_icon {
      position: absolute;
      color: #c3cad9;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
    input {
      padding: 4px 6px 4px 40px;
      background: #ffffff;
      border: 0.5px solid #cfd7df;
      border-radius: 4px;
      &::placeholder {
        font-size: 12px;
        line-height: 18px;
        color: #b5b5c3;
      }
    }
  }
  .action_select_all {
    display: flex;
    font-size: 12px;
    line-height: 20px;
    color: #183247;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    input {
      width: auto;
      flex-shrink: 0;
    }
    label {
      white-space: nowrap;
      cursor: pointer;
    }
  }
}
.action_header_right {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
}
.show_page_num {
  font-size: 12px;
  line-height: 18px;
  color: #6f7c87;
}
.buttons_page_move {
  display: flex;
  button {
    border: 1px solid #cfd7df;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    color: #c3cad9;
    background-color: transparent;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    &:hover {
      box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.4);
    }
  }
  .page_move_prev {
  }
  .page_move_next {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
.sidebar_toggle {
  font-size: 18px;
  border: 1px solid #cfd7df;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  color: #c3cad9;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  &:hover {
    box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.4);
  }
}

.admin_page_wrapper {
  padding: 15px;
}
.admin_leftside_nav_content_wrapper {
  display: grid;
  grid-template-columns: 210px 1fr;
  min-height: calc(100vh - 80px);
  background-color: #fff;
}
.conversation_items_wrapper {
  padding: 14px;
  padding-top: 0;
  background-color: #fff;
  list-style: none;
  border-left: 1px solid #eeeff3;
}

.admin_leftside_nav {
  background-color: #fff;
  ul {
    list-style: none;
  }
  .admin_leftside_nav_list {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s;
    font-size: 13px;
    line-height: 18px;
    color: #333333;
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      background: #ededed;
      opacity: 0.5;
    }
    &.active {
      background-color: #e5f2fd;
    }
    &:hover {
      background-color: #ebeff3;
    }
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}

// Table
.admin_table_wrapper {
  background-color: #fff;
  transition: margin 0.3s;
  padding-bottom: 5px;
}
.admin_table_wrapper {
  table {
    border-spacing: 0;
    border-collapse: collapse;
    .selection-cell,
    .selection-cell-header {
      width: 60px;
    }
    th {
      text-align: left;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #464e5f;
      background: #f5f7f9;
      &:not(:last-child) {
        border-right: 1px solid rgba(207, 215, 223, 0.5);
      }
      &.sortable {
        position: relative;
        cursor: pointer;
        .order {
          position: absolute;
          right: 10px;
          top: 5px;
          .dropdown:first-child {
            -moz-transform: translateX(8px) translateY(7px);
            -webkit-transform: translateX(8px) translateY(7px);
            -o-transform: translateX(8px) translateY(7px);
            -ms-transform: translateX(8px) translateY(7px);
            transform: translateX(8px) translateY(7px);
            display: inline-block;
          }
          .dropdown > .caret {
            margin: 10px 0 10px 5px;
            color: #cccccc;
          }
          .dropup > .caret {
            margin: 10px 0;
            color: #cccccc;
          }
        }
        .react-bootstrap-table-sort-order {
          position: absolute;
          right: 10px;
          top: 7px;
        }
      }
    }
    th,
    td {
      padding: 10px;
    }
    td {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #464e5f;
    }
    tr:not(:last-child) {
      border-bottom: 1px solid #e4e6ef;
    }

    tr {
      &.selection-row {
        td {
          background: #0075ff1c;
        }
      }
    }
  }
}
.table_cell_image_name_wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  .cell_image_wrapper {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    &.round {
      width: 40px;
      height: 40px;
      img {
        border-radius: 50%;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }
}
.cell_name {
  max-width: 250px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.admin_cell_bold_text {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}
.admin_cell_light_text {
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #999999;
}

// Dropdown styles
.table_cell_action {
  text-align: right;
}
.table_cell_action_more {
  color: #c3cad9;
  font-size: 15px;
  box-sizing: content-box;
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: #f7f7f7;
  }
}
.action_more_dropdown_wrapper {
  width: max-content;
  margin-left: auto;
  position: relative;
  &:hover .action_more_dropdown {
    visibility: visible;
    opacity: 1;
  }
}
.action_more_dropdown_parent {
  position: relative;
  &:hover {
    .action_more_dropdown {
      visibility: visible;
      opacity: 1;
    }
  }
}
.action_more_dropdown {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  right: 0;
  top: calc(100% - 10px);
  background: #ffffff;
  box-shadow: 0px 5px 8px #ebeff3;
  padding: 8px;
  min-width: 140px;
  max-width: 160px;
  z-index: 1;
  ul {
    list-style: none;
    li,
    a {
      width: 100%;
      padding: 6px 8px;
      font-size: 12px;
      line-height: 15px;
      color: #12344d;
      display: flex;
      align-items: center;
      gap: 6px;
      transition: background-color color 0.3s;
      cursor: pointer;
      &:hover {
        background-color: #f5f6fc;
        color: #f47f20;
      }
    }
    a {
      padding: 0;
    }
  }
}
.sidebar_present {
  margin-right: 310px !important;
}
.filter_sidebar_open {
  transform: translateX(0);
}

// ============ admin Form style start ==============

// Form
.admin_form_section_title {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #12344d;
  padding-bottom: 12px;
  margin-bottom: 14px;
  border-bottom: 0.5px solid #ebeff3;
}
.admin_input_group_wrapper {
  margin-bottom: 15px;
  border: 1px solid #ebeef6;
  padding: 14px;
  padding-bottom: 0;
  position: relative;
}
.admin_form_group {
  margin-bottom: 14px;
  font-size: 12px;
}
// .admin_label {
//   display: block;
//   font-weight: 500;
//   font-size: 11px;
//   line-height: 22px;
//   color: #12344d;
// }
.admin_label {
  display: block;
  font-weight: 500;
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 1.42857;
  color: #333;
}

// .admin_input {
//   width: 100%;
//   padding: 5.5px 10px;
//   font-size: 11px;
//   border: 1px solid rgba(235, 239, 243, 1);
//   &::placeholder {
//     line-height: 15px;
//     color: #b5b5c3;
//   }
// }
.admin_input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 5px 10px;
  font-size: 12px;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  &:focus {
    border-color: #12344d;
  }

  &::placeholder {
    line-height: 15px;
    color: #ccc;
    font-weight: 300;
    // color: #b5b5c3;
  }
}
textarea.admin_input {
  height: unset;
}

.read_only {
  input,
  .admin_input {
    background-color: #f5f7f9;
  }
}
.admin_form_group {
  textarea {
    min-height: 120px;
    &.sm {
      min-height: 34px;
    }
  }
}
.admin_form_col_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 14px;
}
.admin_form_col_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 14px;
}
.admin_form_col_4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 14px;
}
.admin_input_repetable_wrapper {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  // gap: 8px;
}
.admin_input_repetable_add_icon {
  color: #c3cad9;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.2s;
  &:hover {
    color: #f47f20;
  }
}
.admin_input_repetable_delete_icon {
  color: #999999;
  font-size: 20px;
  transition: color 0.2s;
  cursor: pointer;
  &:hover {
    color: #bc4345;
  }
}

// uplaod image
.admin_upload_image {
  width: 100px;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: #f3f7f8;
  border-radius: 4px;
  border: none;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
  }
  .admin_upload_image_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #3699ff;
  }
}

.upload_preview {
  position: relative;
  width: 100px;
  height: 60px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}
.upload_delete_icon {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 13px;
  height: 13px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  color: #fff;
  border: none;
  &:hover {
    color: red;
  }
}
.upload_with_instruction_wrapper {
  display: flex;
  align-items: center;
  gap: 14px;
  .upload_instruction {
    font-size: 11px;
    line-height: 18px;
    color: #696969;
  }
}

// Add new buttons
.add_new_button {
  margin-top: 14px;
  margin-bottom: 14px;
  font-size: 13px;
  line-height: 15px;
  color: #2c5cc5;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background-color: transparent;
  padding: 2px;
  padding-left: 0;
  svg {
    font-size: 15px;
    color: #00755d;
  }
}
.admin_action_button_wrapper {
  display: flex;
  align-items: center;
}
.admin_action_button {
  min-width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #c3cad9;
}

// Repetable fields showing
.repetable_field_show_lists {
  list-style: none;
  margin-bottom: 14px;
}
.repetable_field_show_list {
  display: flex;
  padding: 10px;
  gap: 10px;
  border: 1px solid #ebeef6;
  svg {
    color: #c3cad9;
    cursor: pointer;
  }
}
.repetable_field_show_left {
  flex-shrink: 0;
}
.repetable_field_show_right {
  width: 100%;
}
.show_list_head_actions {
  display: flex;
  align-items: center;
  margin-right: -4px;
}

.show_list_actions_drag {
  cursor: grab !important;
}
.show_list_actions_edit {
  padding: 4px;
  box-sizing: content-box;
  transition: color 0.2s;
  &:hover {
    color: #2c5cc5;
  }
}
.show_list_actions_delete {
  padding: 4px;
  box-sizing: content-box;
  transition: color 0.2s;
  &:hover {
    color: #bc4345;
  }
}
.repetable_field_show_list_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.show_list_head_title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #12344d;
}
.show_list_desc_lists {
  list-style: none;
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  li {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
.show_list_desc_title {
  font-size: 11px;
  line-height: 18px;
  color: #5e6278;
}
.show_list_desc_text {
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #12344d;
}

// ============ admin Form style end ==============

// Sidebar for Filter
.filter_sidebar {
  position: fixed;
  right: 0;
  bottom: 0px;
  top: 106px;
  width: 310px;
  padding: 14px;
  background-color: #f5f7f9;
  transform: translateX(100%);
  transition: transform 0.3s;
  border-left: 1px solid #eee;
  &.filter_sidebar_open {
    transform: translateX(0);
  }
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.filter_sidebar_title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #183247;
  margin-bottom: 10px;
}
.filter_sidebar_body {
  overflow-y: auto;
  overflow-wrap: anywhere;
}
.filter_sidebar_footer {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// ============ admin drawer style start ==============

// Admin drawer
.admin_drawer {
  overflow: hidden;
  @media (min-width: 993px) {
    width: 35%;
    &.admin_drawer_md {
      width: 55%;
    }
  }
  @media (max-width: 992px) {
    width: 50%;
    &.admin_drawer_md {
      width: 75%;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}
.admin_drawer_container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.admin_drawer_head {
  padding: 15px;
  position: sticky;
  top: 0px;
  height: 55px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebeef6;
}
.admin_drawer_head_right {
  width: 20px;
  height: 20px;
  color: #c3cad9;
  border: none;
  background: transparent;
  font-size: 18px;
  transition: color 0.2s;
  &:hover {
    color: red;
  }
}
.admin_drawer_head_title {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #3f4254;
}
.admin_drawer_body_outer {
  padding-right: 3px;
}
.admin_drawer_body {
  height: calc(100vh - 55px - 55px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 14px 8px 14px 14px;
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(202, 202, 202);
  }
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
    cursor: pointer;
  }
}
.admin_drawer_footer {
  border-top: 1px solid #ebeef6;
  padding: 15px;
  position: sticky;
  bottom: 0px;
  height: 55px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin_drawer_footer_left,
.admin_drawer_footer_right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.instruction_text {
  font-size: 11px;
  line-height: 20px;
  color: #696969;
  margin-bottom: 14px;
}
// ============ admin drawer style end ==============

// ============ Conversation items styles stat =============
.conversiation_items {
  list-style: none;
}
.conversiation_item {
  display: flex;
  padding: 10px 0px;
  border-bottom: 1px solid #e4e6ef;
}
.conversiation_item_checkbox {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 40px;
  flex-shrink: 0;
}
.unseen {
  background: #ebeff3;
  a {
    font-weight: 500;
  }
}
.conversiation_item_person {
  flex-shrink: 0;
  padding-right: 30px;
}
.conversiation_item_description_title {
  margin-bottom: 3px;
  font-size: 12px;
  a {
    font-size: 12.5px;
    line-height: 18px;
    color: #000000;
  }
}
.conversiation_item_description_text {
  color: #333;
  font-size: 12px;
  //   max-width: 600px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
// ============ Conversation items styles end =============

//============= Chat Related Style start ==============

// Chat related
.chat_wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}
.chat_details_wrapper {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

// Chat list
.chat_list_wrapper {
  overflow: auto;
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(202, 202, 202);
  }
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
    cursor: pointer;
  }
}
.chat_lists {
  list-style: none;
}
.chat_list {
  padding: 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.3s;
  &.unseen,
  &:hover {
    background-color: #ebeff3;
  }
}
.chat_list_person_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.chat_list_avatar_wrapper {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.chat_list_person {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.chat_list_person_name {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
}
.chat_list_person_text {
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.chat_list_time {
  font-size: 11px;
  line-height: 18px;
  text-align: right;
  color: #999999;
  flex-shrink: 0;
}

// Chat details
.chat_details_wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  overflow: auto;
}
.chat_details_body {
  height: 100%;
  overflow: auto;
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(202, 202, 202);
  }
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
    cursor: pointer;
  }
}
.chat_details_head {
  padding: sticky;
  top: 0;
  padding: 18px 14px;
  border-bottom: 1px solid #eee;
}
.chat_details_head_title {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}

.chat_details_body {
  padding: 14px 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.chat_details_body_content {
  margin-top: auto;
}
.chat_time {
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #999999;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
  &::before {
    content: "";
    border: 1px solid #f5f7f9;
    width: 100%;
  }
  &::after {
    content: "";
    border: 1px solid #f5f7f9;
    width: 100%;
  }
}
.chat_message_lists {
  list-style: none;
  display: flex;
  flex-direction: column;
}
.chat_message_list {
  margin-bottom: 14px;
  max-width: 70%;
}
.chat_message_list {
  &.me {
    text-align: right;
    align-self: flex-end;
    .chat_message_wrapper {
      flex-direction: row-reverse;
    }
    .chat_message_name,
    .chat_message_time {
      margin-right: 25px;
    }
    .chat_message_item {
      background-color: #fff;
      border: 1px solid #e4e6ef;
      border-radius: 15px 0px 0px 15px;
      &:last-child {
        border-radius: 15px 0px 15px 15px;
      }
    }
  }
}

.chat_message_group {
  display: flex;
  flex-direction: column;
}
.me {
  .chat_message_group {
    align-items: flex-end;
  }
}
.chat_message_group_check {
  display: flex;
  .chat_message_check_icon {
    flex-shrink: 0;
    align-self: flex-end;
    color: #c3cad9;
  }
}
.chat_message_name {
  font-size: 11px;
  line-height: 18px;
  color: #999999;
  margin-left: 25px;
}
.chat_message_wrapper {
  display: flex;
  gap: 5px;
}
.chat_message_avatar {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.chat_message_editable_wrapper {
  display: flex;
  &:hover {
    .chat_message_edit_wrapper {
      visibility: visible;
      opacity: 1;
    }
  }
  .action_more_dropdown {
    right: unset;
    left: 0;
  }
}
.me {
  .chat_message_editable_wrapper {
    flex-direction: row-reverse;
    .action_more_dropdown {
      right: 0;
      left: unset;
    }
  }
}
.chat_message_edit_wrapper {
  flex-shrink: 0;
  align-self: flex-start;
}
.chat_message_edit_wrapper {
  visibility: hidden;
  opacity: 0;
}
.chat_messsage_more_icon {
  color: #c3cad9;
  font-size: 18px;
}

.chat_message_item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: max-content;
  // background-color: #bedbf5;
  background-color: #eaf0ff;
  padding: 10px 15px;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
  border-radius: 0px 15px 15px 0px;
  margin-bottom: 1px;
}
.chat_message_group {
  .chat_message_editable_wrapper:last-child {
    .chat_message_item {
      border-radius: 0px 15px 15px 15px;
    }
  }
}
.me {
  .chat_message_item {
    border-radius: 15px 0px 0px 15px;
  }
  .chat_message_group {
    .chat_message_editable_wrapper:last-child {
      .chat_message_item {
        border-radius: 15px 0px 15px 15px;
      }
    }
  }
}

.chat_message_images {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
  img {
    max-width: 150px;
    border-radius: 5px;
  }
}
.me {
  .chat_message_images {
    align-items: flex-end;
    // justify-content: flex-end;
  }
}
.chat_message_time {
  font-size: 11px;
  line-height: 18px;
  color: #999999;
  margin-left: 25px;
}
.chat_details_footer {
  padding: sticky;
  bottom: 0;
  border-radius: 4px;
  border: 1px solid #e4e6ef;
  margin: 10px;
  margin-top: 0;
}
.chat_details_footer_textarea {
  padding: 14px;
}
.chat_details_footer_actions {
  border-top: 1px solid #eee;
  padding: 6px 14px;
  display: flex;
  justify-content: space-between;
}
.chat_details_footer_actions_left {
  display: flex;
}

// Chat person info related styles
.chat_details_person_wrapper {
  height: 100%;
  overflow: auto;
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(202, 202, 202);
  }
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
    cursor: pointer;
  }
}
.chat_person_detail_avatar_wrapper {
  padding: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #eee;
}
.chat_person_detail_avatar {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.chat_person_detail_name_wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.chat_person_detail_name {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2c5cc5;
}
.chat_person_detail_text_grey {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #bababa;
}
.chat_person_detail_text_dark {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #15354e;
}
.chat_person_detail_info {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.chat_person_detail_info_item {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 8px;
}

.admin_divider {
  background-color: #eee;
  height: 1px;
}
//============= Chat Related Style end ==============

//=========== bootstrap css customize ============//
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  & > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
  .col {
    flex: 1 0 0%;
  }
  .col-md > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
.react-bootstrap-table-pagination {
  padding: 0 15px;
  margin-top: 20px;
  .dropdown {
    position: relative;
    float: left;
    margin-right: 15px;
  }
  .dropdown-menu {
    position: absolute;
    left: 0;
    top: 40px;
    list-style: none;
    background: #fff;
    border: 1px solid #ddd;
    padding: 5px 15px;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    z-index: -100;
    &.show {
      visibility: visible;
      opacity: 1;
      z-index: 1;
    }
  }
  .react-bootstrap-table-pagination-total {
    position: relative;
    padding-top: 5px;
    display: inline-block;
    font-size: 14px;
  }
  .pagination {
    position: relative;
    list-style: none;
    display: flex;
    float: right;
    gap: 5px;
  }
  .pagination li {
    display: inline-flex;
    a {
      min-width: 30px;
      height: 30px;
      border-radius: 5px;
      background-color: #ddd;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      font-size: 13px;
    }
    &.active a {
      background-color: #f47f20;
      color: #fff;
    }
  }
}
.absulet-btngroup {
  position: absolute;
  bottom: 13px;
  right: 14px;
  z-index: 1;
  display: flex;
  gap: 10px;
}
.noaperance {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  padding: 5px;
  width: auto;
  border: none;
  background: transparent;
  pointer-events: none;
}

.error_msg {
  font-size: 13px;
  margin: 0;
  margin-bottom: -5px;
}

.error_box {
  background: #ffebeb;
  &::placeholder {
    color: #333 !important;
  }
}

.d-flex {
  display: flex;
  gap: 10px;
}
.admin_scholarship_level_item {
  padding: 2px 5px;
  background-color: #f5f7f9;
  border-radius: 5px;
}

.action_loading {
  position: absolute;
  left: 15px;
  top: 10px;
}

// Top paginate related styles
.top_paginate_root {
  .top_paginate_toolbar {
    padding-left: 0 !important;
    .top_paginate_actions {
      margin-left: 8px;
      display: flex;
      button {
        padding: 0;
        border: 1px solid #cfd7df;
        border-radius: 4px;
        width: 30px;
        height: 30px;
        color: #c3cad9;
        background-color: transparent;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        &:disabled {
          background-color: transparent !important;
          color: #eee !important;
        }
        &:hover {
          box-shadow: inset 0 0 0 0.5px rgb(0 0 0 / 40%);
        }
        &:first-child {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        &:nth-child(2) {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }
    }
  }
}

.table-profile {
  display: flex;
  justify-content: left;
  align-items: center;
  .profile-image {
    flex-shrink: 0;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    overflow: hidden;
    object-fit: contain;
    margin-right: 10px;
    border: 1px solid #eee;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .profile-name {
    display: block;
    font-size: 13px;
    font-weight: 500;
  }
  .profile-designation {
    display: block;
    font-size: 12px;
    color: #777;
  }
  .profile-subDesignation {
    display: block;
    font-size: 12px;
    color: #777;
  }
  .profile-email {
    display: block;
    font-size: 12px;
    color: #777;
  }
}
.button-group {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  button {
    width: 50%;
  }
}
.react-datepicker-popper {
  z-index: 1000;
}
.react-datepicker__input-container {
  input {
    border: 1px solid #ebeff3;
    padding: 5px 10px;
    font-size: 12px;
  }
}

.no_record_found {
  text-align: center;
  padding-top: 20px;
  font-size: 14px;
  padding-bottom: 16px;
}
table {
  .tableImage,
  .tableFileIcon {
    display: inline-block;
    max-height: 55px;
    max-width: 55px;
  }
  .tableVideo {
    display: inline-block;
    max-height: 85px;
    max-width: 155px;
  }
}
.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sun-editor {
  position: relative;
  z-index: 0;
}
.admin_input_group_wrapper {
  .exam_actions {
    position: absolute;
    right: 5px;
    top: 3px;
  }
  .btn-rounded-remove {
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    font-size: 13px;
    width: 22px;
    height: 22px;
    border: none !important;
    background: rgb(243, 243, 243);
    color: rgb(88, 88, 88);
    line-height: 1;
    padding: 0;
    margin: 0;
    -webkit-transition: all 300ms ease-in;
    -moz-transition: all 300ms ease-in;
    -ms-transition: all 300ms ease-in;
    -o-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
  }
  &:hover .btn-rounded-remove {
    color: rgb(255, 0, 0);
    background: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
  }
}

.statusLabel {
  display: inline-block;
  border-radius: 15px;
  padding: 3px 10px;
  font-weight: 400;
  font-size: 12px;
  &.Published {
    background: #033a0014;
    color: #005234;
  }
  &.Draft {
    background: #033a0014;
    color: #9b9b9b;
    padding: 3px 12px;
  }
  &.Inactive {
    background: #033a0014;
    color: #9b9b9b;
    padding: 3px 10px;
  }
  &.Trash {
    background: #ff00008a;
    color: #ffffff;
    padding: 3px 12px;
  }
}

.mediaFlex {
  display: flex;
  gap: 15px;
  align-items: center;
}
.mediaFile {
  flex-shrink: 0;
  width: 180px;
  border: 1px solid #e7e7e7;
  padding: 5px;
}
.mediaForm {
  width: 100%;
}
.zodit-add-new-line {
  display: none;
}
.mobile-number-input {
  .react-tel-input .form-control {
    padding: 4px 40px;
    border-radius: 0;
  }
}
