.student_admin_header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  .student_admin_head {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    color: #8390b1;
  }
  .student_admin_header_wrapper_right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .student_admin_header_search_wrapper {
    position: relative;
    .student_admin_header_search_icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #8390b1;
    }
    input {
      padding: 10px;
      padding-left: 32px;
      background: #f5f8fa;
      border-radius: 6px;
      border: none;
      &:focus {
        outline: none;
      }
    }
  }
}
